body {
    font-family: Arial, sans-serif;
    background-color: #fff;
    color: #000;
}

.form-check-input[type="checkbox"] {
    border-color: darkgray;
}

.page-container {
    max-width: 1000px;
}

.page-container-smaller {
    max-width: 680px;
    margin: 0 auto;
}

.mandatory {
    color: red;
}

.button {
    color: #fff;
    min-width: 120px;
    font-size: smaller;
    border-radius: 0px;
    border: 1px solid transparent;
    padding: 15px 20px 15px 20px;
    font-weight: bold;
    cursor: pointer;
}

    .button:hover {
        border: 1px solid #000;
        background-color: #fff;
        color: #000;
    }

    .button:disabled {
        color: white;
        border: 1px solid transparent;
        cursor: default;
    }

.btn-register {
    background-color: #8E6713;
    opacity: 1;
}

    .btn-register:disabled {
        background-color: #8E6713;
        opacity: 0.6;
    }

.btn-deregister {
    background-color: #d3d3d3;
    opacity: 1;
}

    .btn-deregister:disabled {
        background-color: #d3d3d3;
        opacity: 0.6;
    }

.description {
    color: rgb(53, 53, 53);
}

.form-control,
.form-select {
    border: 1px solid black;
    border-radius: 0px;
}

.delivery_icon {
    max-width: 55px;
    vertical-align: top;
    margin-top: 4px;
    padding-left: 17px;
    padding-right: 17px;
}

.delivery-description {
    margin-left: 55px !important;
}

.payment_icon {
    max-width: 60px;
    vertical-align: top;
    padding-right: 5px;
}

.payment-description {
    margin-left: 60px !important;
}

/*checkboxes and radio-buttons*/

input[type="checkbox"],
input[type="radio"] {
    margin-top: 6px;
}

.form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
}

.payment-names {
    width: 100%;
}
